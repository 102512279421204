<template>
  <b-modal
    :active="true"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    @close="$emit('close')"
  >
    <div class="modal-card modal-container">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t("custom_domain_modal.set_custom_domain") }}
        </p>
      </header>
      <section class="modal-card-body">
        <div class="flex flex-col">
          <div id="id-div-biew-domain">
            <div class="flex relative justify-center w-full">
              <div class="flex form-container w-3/4">
                <div class="radio-container">
                  <b-radio
                    v-model="localDomainType"
                    name="domainType"
                    native-value="biew-domain"
                  >
                  </b-radio>
                </div>
                <div class="flex flex-col w-full">
                  <b-field
                    :label="$t('builder.subdomain')"
                    :type="subdomainInputType"
                  >
                    <div class="flex">
                      <b-input
                        id="input-id-subdomain"
                        v-model="localSubdomain"
                        placeholder="mywebsite"
                        expanded
                        :disabled="disableEditBiewDomain"
                        class="w-full custom-input"
                      ></b-input>
                      <p class="control">
                        <span
                          class="button custom-button"
                          :disabled="disableEditBiewDomain"
                        >
                          {{ subfixDomain }}
                        </span>
                      </p>
                    </div>
                  </b-field>
                  <p
                    v-if="
                      subdomainInputMessage && localDomainType === 'biew-domain'
                    "
                    class="help"
                    :class="[subdomainInputType]"
                  >
                    {{ subdomainInputMessage }}
                  </p>
                  <span
                    :class="disableEditBiewDomain ? 'has-text-grey-light' : ''"
                  >
                    {{ $t("allowed_modify_subdomain") }}
                  </span>
                  <div>
                    <b-button
                      class="mt-2"
                      type="is-info"
                      :disabled="disableBtnVerifySubdomain"
                      :loading="checkingAvailability"
                      @click="checkAvailability"
                    >
                      {{ $t("builder.check_availability") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="div-id-divider" class="my-3"></div>
          <div
            id="id-div-custom-domain-form"
            class="relative flex justify-center"
          >
            <div class="flex w-3/4">
              <div class="radio-container">
                <b-radio
                  v-model="localDomainType"
                  name="domainType"
                  native-value="custom-domain"
                >
                </b-radio>
              </div>
              <div class="custom-domain-container w-full">
                <b-field :label="$t('your_custom_domain')">
                  <b-input
                    id="input-id-custom-domain"
                    v-model="localCustomDomain"
                    type="text"
                    :placeholder="$t('custom_domain_modal.website_example')"
                    required
                    class="w-full"
                    :disabled="!showButtons"
                  >
                  </b-input>
                </b-field>
                <div class="flex justify-center items-center">
                  <b-button
                    class="button is-light w-full"
                    :disabled="updatingDomain"
                    @click="handleClickCancel"
                  >
                    {{ $t("custom_domain_modal.cancel") }}
                  </b-button>
                  <b-button
                    class="button is-primary ml-4 w-full"
                    :loading="updatingDomain"
                    :disabled="disableBtnSaveDomain"
                    @click="save"
                  >
                    {{ $t("custom_domain_modal.save") }}
                  </b-button>
                </div>
                <div
                  v-show="showInstructions"
                  class="border-t border-gray-300 mt-4 pt-2"
                >
                  <div class="font-bold text-lg">
                    {{ $t("custom_domain_modal.instructions") }}
                  </div>
                  <div class="font-bold mt-2">
                    {{ $t("custom_domain_modal.instructions2") }}
                  </div>
                  <div>
                    {{ $t("custom_domain_modal.instructions3") }}
                  </div>
                  <div class="mt-2 p-2 bg-gray-200 rounded-lg font-bold">
                    {{ $t("custom_domain_modal.instructions4") }}
                  </div>
                  <div class="mt-4">
                    {{ $t("custom_domain_modal.instructions5") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.modal-container {
  width: 600px;
  border-radius: 5px;
}
.custom-button {
  background: rgba(225, 230, 255, 1);
  cursor: default;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:hover {
    background: rgba(225, 230, 255, 1);
    border-color: inherit;
  }
}
.custom-input > input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import { CUSTOM_DOMAIN, BIEW_DOMAIN } from "@/constants/action-types";
import {
  SET_CUSTOM_DOMAIN,
  SET_DOMAIN_TYPE
} from "@/store/modules/builder/mutation-types";
import { isValidSubdomain } from "@/utils/validation";
import * as Sentry from "@sentry/browser";
export default {
  name: "CustomDomainModal",
  props: {
    changeSubdomain: {
      type: Function,
      default: () => undefined
    }
  },
  data() {
    return {
      editMode: false,
      domain: this.$store.state.builder.domain,
      subdomainInputMessage: "",
      subdomainInputType: "",
      customDomainInputType: "",
      customDomainInputMessage: "",
      updatingDomain: false,
      checkingAvailability: false,
      localCustomDomain: "",
      localDomainType: "",
      localSubdomain: "",
      subfixDomain: ".getbiew.com"
    };
  },
  computed: {
    ...mapGetters("builder"),
    showInstructions() {
      return this.localDomainType === CUSTOM_DOMAIN;
    },
    customDomain: {
      get() {
        return this.$store.state.builder.customDomain;
      },
      set(val) {
        this.$store.commit(`builder/${SET_CUSTOM_DOMAIN}`, val);
      }
    },
    domainType: {
      get() {
        return this.$store.state.builder.domainType;
      },
      set(val) {
        this.$store.commit(`builder/${SET_DOMAIN_TYPE}`, val);
      }
    },

    disableBtnVerifySubdomain() {
      return this.disableEditBiewDomain || !this.isValidSubdomain;
    },
    disableBtnSaveDomain() {
      if (this.localDomainType === BIEW_DOMAIN) {
        return !this.isValidSubdomain || this.domain.trim() === "";
      } else {
        return !this.isValidSubdomain || this.localCustomDomain.trim() === "";
      }
    },
    isValidSubdomain() {
      return isValidSubdomain(this.localSubdomain);
    },
    disableEditBiewDomain() {
      return this.localDomainType === CUSTOM_DOMAIN;
    },
    stateDomain() {
      return this.$store.state.builder.domain;
    },
    showButtons() {
      return this.localDomainType === CUSTOM_DOMAIN;
    },
    editableSubdomain: {
      get() {
        return this.$store.state.builder.subdomain;
      },
      set(val) {
        this.$store.commit("builder/SET_SITE_SUBDOMAIN", val);
      }
    }
  },
  watch: {
    isValidSubdomain(newVal) {
      if (newVal === false) {
        this.subdomainInputType = "is-danger";
        this.subdomainInputMessage = "Not a valid subdomain";
      } else {
        this.subdomainInputType = "";
        this.subdomainInputMessage = "";
      }
    },
    localDomainType(val) {
      if (val === CUSTOM_DOMAIN) {
        this.subdomainInputMessage = "";
        this.subdomainInputType = "";
      }
    }
  },
  mounted() {
    let biewDomain = this.$store.state.builder.subdomain;
    if (
      this.$store.state.builder.biewDomain &&
      this.$store.state.builder.biewDomain !== ""
    ) {
      biewDomain = this.$store.state.builder.biewDomain.split(".")[0];
    }
    this.localDomainType = this.$store.state.builder.domainType;
    this.localCustomDomain = this.$store.state.builder.customDomain;
    this.localSubdomain = biewDomain;
  },
  methods: {
    async save() {
      try {
        this.updatingDomain = true;
        const body = {
          domain:
            this.localDomainType === CUSTOM_DOMAIN
              ? this.localCustomDomain
              : `${this.localSubdomain}.getbiew.com`,
          subdomain: this.localSubdomain,
          domainType: this.localDomainType
        };
        await this.$store.dispatch("builder/verifyDomain", body);
        this.customDomain = this.localCustomDomain;
        this.domainType = this.localDomainType;
        this.subdomainInputType = "";
        this.subdomainInputMessage = "";
        this.$emit("change-subdomain", this.localSubdomain);
        this.$emit("close");
      } catch (error) {
        if (this.localDomainType === BIEW_DOMAIN) {
          this.subdomainInputType = "is-danger";
        }
        Sentry.captureException(error);
        this.$buefy.notification.open({
          message: this.$t("error_messages.updating_custom_domain"),
          type: "is-danger"
        });
      } finally {
        this.updatingDomain = false;
      }
    },
    async checkAvailability() {
      try {
        this.checkingAvailability = true;
        const body = {
          domain: `${this.localSubdomain}.getbiew.com`,
          subdomain: this.localSubdomain,
          domainType: this.localDomainType
        };
        const res = await this.$store.dispatch("builder/verifyDomain", body);
        this.subdomainInputType = "is-success";
        this.subdomainInputMessage = res?.message || "";
        this.$emit("change-subdomain", this.localSubdomain);
      } catch (error) {
        this.subdomainInputType = "is-danger";
        if (error?.response?.data?.message) {
          this.subdomainInputMessage = error.response.data.message;
        } else {
          this.subdomainInputMessage = "An error ocurred, try again or refresh";
        }
      } finally {
        this.checkingAvailability = false;
      }
    },
    handleClickCancel() {
      this.localDomainType = this.$store.state.builder.domainType;
      this.localCustomDomain = this.$store.state.builder.customDomain;
      this.localSubdomain = this.$store.state.builder.subdomain;
      this.$emit("close");
    },
    cancel() {
      this.domain = this.stateDomain;
    }
  }
};
</script>
