<template>
  <nav
    class="
      h-20
      p-6
      flex
      items-center
      justify-between
      flex-wrap
      bg-white
      border-b-2 border-gray-200
      shadow-sm
    "
    :class="{ fixedNav: fixedNav }"
    :style="{
      'background-color': backgroundColor,
      'border-color': backgroundColor
    }"
  >
    <div
      class="flex w-full items-center flex-shrink-0 text-gray-900 h-full border-black"
    >
      <img v-if="logo" :src="logo" alt="some logo" class="h-full" />
      <div class="flex flex-grow items-center justify-center">
        <span class="font-semibold text-xl tracking-tight">
          {{ siteName }}
        </span>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "BNavigation",

  props: {
    backgroundColor: {
      type: String,
      default: null
    },
    logo: {
      type: String,
      default: ""
    },
    siteName: {
      type: String,
      default: "My website"
    },
    fixedNav: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.fixedNav {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
