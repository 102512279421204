<template>
  <div
    ref="label"
    class="flex items-center mt-2"
    :disabled="disabled"
    @click="focus"
    @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      ref="input"
      v-model="computedValue"
      v-bind="$attrs"
      type="checkbox"
      class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      :name="name"
      :value="nativeValue"
      :disabled="disabled"
      :required="required"
      :true-value="trueValue"
      :false-value="falseValue"
      @click.stop
    />
    <span class="ml-2 block"><slot /></span>
  </div>
</template>

<script>
export default {
  name: "BCheckbox",

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: ""
    },
    nativeValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: ""
    },
    disabled: Boolean,
    required: Boolean,
    name: { type: String, default: "" },
    size: { type: String, default: "" },
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: false
    }
  },

  data() {
    return {
      newValue: this.value
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      }
    }
  },

  watch: {
    /**
     * When v-model change, set internal value.
     */
    value(value) {
      this.newValue = value;
    }
  },
  methods: {
    focus() {
      // MacOS FireFox and Safari do not focus when clicked
      this.$refs.input.focus();
    }
  }
};
</script>
