<template>
  <div
    class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
    role="alert"
  >
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "BNotification",

  props: {
    message: {
      type: String,
      default: ""
    },
    duration: {
      type: Number,
      default: 3000
    }
  },

  created() {
    setTimeout(() => this.$emit("hide"), this.duration);
  }
};
</script>
