// Convertir una cadena en un ArrayBuffer
async function strToArrayBuffer(str) {
  const encoder = new TextEncoder();
  return encoder.encode(str).buffer;
}

// Generar una clave AES a partir de una contraseña
async function generateKey(password) {
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );
  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: new Uint8Array(16),
      iterations: 100000,
      hash: "SHA-256"
    },
    keyMaterial,
    {
      name: "AES-CBC",
      length: 256
    },
    true,
    ["encrypt", "decrypt"]
  );
}

// Encriptar texto
export async function encryptText(plainText, password) {
  const key = await generateKey(password);
  const iv = crypto.getRandomValues(new Uint8Array(16)); // Vector de inicialización
  const encryptedContent = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    key,
    await strToArrayBuffer(plainText)
  );

  // Convertir IV y cifrado a base64 para transmitir
  const ivBase64 = btoa(String.fromCharCode(...new Uint8Array(iv)));
  const encryptedBase64 = btoa(
    String.fromCharCode(...new Uint8Array(encryptedContent))
  );

  return `${ivBase64}:${encryptedBase64}`;
}
