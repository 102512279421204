<template>
  <div class="text-left">
    <div>
      <b-field :label="$t('builder.website_name')">
        <b-input
          v-model="websiteName"
          placeholder="My brand new website"
        ></b-input>
      </b-field>
    </div>
    <div class="mt-4">
      <b-field
        v-if="showCustomDomainField"
        :label="$t('builder.custom_domain')"
        :type="domainInputType"
      >
        <b-input
          :value="showCustomDomainField ? customDomain : domain"
          placeholder="www.mywebsite.com"
          expanded
          disabled
        ></b-input>
      </b-field>
      <b-field
        v-else
        :label="$t('builder.subdomain')"
        :type="subdomainInputType"
      >
        <div class="flex">
          <b-input
            v-model="subdomain"
            v-lowercase
            placeholder="mywebsite"
            expanded
            class="w-full"
          ></b-input>
          <p class="control">
            <span class="button">
              {{ subfixDomain }}
            </span>
          </p>
        </div>
      </b-field>
    </div>
    <p v-if="subdomainInputMessage" class="help" :class="[subdomainInputType]">
      {{ subdomainInputMessage }}
    </p>
    <div v-if="!showCustomDomainField" class="flex items-end">
      <b-button
        class="mt-2"
        type="is-info"
        :disabled="!isValidSubdomain"
        :loading="checkingAvailability"
        @click="checkAvailability"
      >
        {{ $t("builder.check_availability") }}
      </b-button>
    </div>
    <div v-if="!isPro" class="flex items-end">
      <pro-label class-container="w-full text-center mt-4">
        {{ $t("builder.customize_your_domain_to_pro") }}
      </pro-label>
    </div>
    <div v-show="isPro && !isNewWebsite" class="flex items-end">
      <b-button class="mt-2" type="is-primary" @click="openCustomDomainModal">
        {{
          isNotSubdomain
            ? $t("builder.edit_custom_domain")
            : $t("builder.add_custom_domain")
        }}
      </b-button>
    </div>
    <div class="mt-4">
      <b-field v-if="isNotSubdomain" :label="$t('builder.subdomain')">
        <div>
          <a
            class="hover:underline"
            :href="`http://${biewDomain}`"
            target="_blank"
          >
            {{ biewDomain }}
          </a>
        </div>
      </b-field>
    </div>
    <div class="border-t border-gray-300 my-6"></div>
    <div>
      <b-alert v-show="showFileLimitError" type="warn">
        <b-icon name="exclamation-triangle" />
        <span class="ml-4">{{ fileError }}</span>
      </b-alert>
      <div class="mb-3 font-bold">{{ $t("builder.logo") }}</div>
      <b-field class="file mt-3">
        <div
          v-show="logoBase64"
          class="w-10 p-1 mr-2 border border-gray-400 rounded flex items-center"
        >
          <div>
            <img :src="logoBase64" />
          </div>
        </div>
        <b-upload v-model="logoFile" expanded accept=".jpg,.jpeg,.png">
          <a class="button is-fullwidth">
            <b-icon name="upload" class="mr-3"></b-icon>
            <span>{{ $t("builder.choose_logo") }}</span>
          </a>
        </b-upload>
        <b-tooltip label="Remove logo">
          <b-button icon-right="trash" class="ml-3" @click="deleteLogo" />
        </b-tooltip>
      </b-field>
      <div class="mt-6 mb-3 font-bold flex items-center">
        {{ $t("builder.favicon") }}
        <pro-label v-if="!isPro" size="small" class-container="ml-2">
          {{ $t("builder_config_payments.pro_only") }}
        </pro-label>
      </div>
      <b-field class="file mt-3" :class="{ disabled: !isPro }">
        <div
          v-show="faviconBase64"
          class="w-10 p-1 mr-2 border border-gray-400 rounded flex items-center"
        >
          <div>
            <img :src="faviconBase64" />
          </div>
        </div>
        <b-tooltip :label="!isPro ? 'Pro feature' : ''" class="w-full">
          <b-upload
            v-model="faviconFile"
            :disabled="!isPro"
            expanded
            accept=".jpg,.jpeg,.png,.ico"
            class="text-red"
          >
            <a class="button is-fullwidth">
              <b-icon name="upload" class="mr-3"></b-icon>
              <span>{{ $t("builder.choose_favicon") }}</span>
            </a>
          </b-upload>
        </b-tooltip>
        <b-tooltip :label="isPro ? 'Remove favicon' : ''">
          <b-button
            icon-right="trash"
            class="ml-3"
            :disabled="!isPro"
            @click="deleteFavicon"
          />
        </b-tooltip>
      </b-field>
      <div class="mt-6 mb-3 font-bold">
        <div class="field">
          <b-switch v-model="fixedNav">{{ $t("builder.nav_fixed") }} </b-switch>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-300 my-6"></div>
    <section-collapse :name="$t('builder.header')">
      <page-header />
    </section-collapse>
    <div class="border-t border-gray-300 my-6"></div>
    <section-collapse :name="$t('builder.top_description')">
      <top-description />
    </section-collapse>
    <div class="border-t border-gray-300 my-6"></div>
    <section-collapse :name="$t('builder.footer')">
      <footer-config />
    </section-collapse>
    <div class="border-t border-gray-300 my-6"></div>
    <section-collapse :name="$t('builder.theme')">
      <theme-config />
    </section-collapse>
    <template>
      <div class="border-t border-gray-300 my-6"></div>
      <section-collapse
        :name="$t('builder.progressive_web_app')"
        :disabled="!isPro"
        is-pro
      >
        <pwa-config />
      </section-collapse>
    </template>
    <custom-domain-modal
      v-show="showCustomDomainModal"
      @close="showCustomDomainModal = false"
      @change-subdomain="handleChangedSubdomain"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BAlert from "@/components/BAlert";
import BIcon from "@/components/BIcon";
import SectionCollapse from "@/components/SectionCollapse";
import CustomDomainModal from "./CustomDomainModal";
import PageHeader from "./PageHeader";
import PwaConfig from "./PwaConfig";
import FooterConfig from "./FooterConfig";
import ThemeConfig from "./ThemeConfig";
import TopDescription from "./TopDescription";
import ProLabel from "../../components/ProLabel.vue";
import { isValidSubdomain } from "@/utils/validation";
import { fileSize, fileToBase64 } from "@/utils/file";

export default {
  name: "BuilderWebsiteConfig",

  components: {
    BAlert,
    BIcon,
    CustomDomainModal,
    FooterConfig,
    PageHeader,
    PwaConfig,
    SectionCollapse,
    ThemeConfig,
    TopDescription,
    ProLabel
  },

  directives: {
    lowercase: {
      update(el) {
        if (el.value) {
          el.value = el.value.toLowerCase();
        }
      }
    }
  },

  data() {
    return {
      checkingAvailability: false,
      subdomainInputMessage: "",
      subdomainInputType: "",
      domainInputType: "",
      showCustomDomainModal: false,
      domain: "",
      faviconFile: {},
      faviconButtonText: "Choose a favicon",
      faviconFileBase64: "",
      logoFile: {},
      logoFileBase64: "",
      logoButtonText: "Choose a logo",
      showFileLimitError: false,
      fileError: this.$t("builder.logo_error"),
      subdomain: ""
    };
  },
  computed: {
    ...mapGetters("builder", ["subfixDomain"]),
    ...mapState("builder", ["domainType", "customDomain", "biewDomain"]),
    editableSubdomain: {
      get() {
        return this.$store.state.builder.subdomain;
      },
      set(val) {
        this.$store.commit("builder/SET_SITE_SUBDOMAIN", val);
      }
    },
    websiteId() {
      return this.$store.state.builder.websiteId;
    },
    favicon() {
      return this.$store.state.builder.favicon;
    },
    faviconBase64() {
      return this.faviconFile.name ? this.faviconFileBase64 : this.favicon;
    },
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    isNotSubdomain() {
      return (
        !this.stateDomain.endsWith(".getbiew.com") &&
        !this.stateDomain.endsWith(".biew.site")
      );
    },
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    isNewWebsite() {
      return !this.$store.state.builder.netlifyId;
    },
    logo() {
      return this.$store.state.builder.logo;
    },
    logoBase64() {
      return this.logoFile.name ? this.logoFileBase64 : this.logo;
    },
    showCustomDomainField() {
      return this.domainType === "custom-domain";
    },
    stateDomain() {
      return this.$store.state.builder.domain;
    },
    biewSubdomain() {
      return this.isNotSubdomain ? `${this.websiteId}${this.subfixDomain}` : "";
    },
    isValidSubdomain() {
      return isValidSubdomain(this.subdomain);
    },
    websiteName: {
      get() {
        return this.$store.state.builder.siteName;
      },
      set(val) {
        this.$store.commit("builder/SET_SITE_NAME", val);
      }
    },
    fixedNav: {
      get() {
        return this.$store.state.builder.fixedNav || false;
      },
      set(val) {
        this.$store.commit(`builder/SET_FIXED_NAV`, val);
      }
    }
  },
  watch: {
    faviconFile(newVal) {
      const size = fileSize(newVal);
      if (size > 0.1) {
        this.fileError = this.$t("builder.logo_error");
        this.showFileLimitError = true;
      } else {
        this.showFileLimitError = false;
        this.faviconButtonText = newVal.name;
        this.saveFavicon(newVal);
      }
    },
    logoFile(newVal) {
      const size = fileSize(newVal);
      if (size > 0.101) {
        this.fileError = this.$t("builder.file_error");
        this.showFileLimitError = true;
      } else {
        this.showFileLimitError = false;
        this.logoButtonText = newVal.name;
        this.saveLogo(newVal);
      }
    },
    stateDomain: {
      immediate: true,
      handler(newVal) {
        this.domain = newVal;
      }
    },
    isValidSubdomain(newVal) {
      if (newVal === false) {
        this.subdomainInputType = "is-danger";
        this.subdomainInputMessage = "Not a valid subdomain";
      } else {
        this.subdomainInputType = "";
        this.subdomainInputMessage = "";
      }
    }
  },
  mounted() {
    this.subdomain = this.$store.state.builder.subdomain;
  },

  methods: {
    handleChangedSubdomain(subdomain) {
      this.subdomainInputType = "";
      this.subdomainInputMessage = "";
      this.subdomain = subdomain;
    },
    async saveFavicon(file) {
      this.faviconFileBase64 = await fileToBase64(file);
      this.$store.commit("builder/SET_FAVICON", this.faviconFileBase64);
    },

    async saveLogo(file) {
      this.logoFileBase64 = await fileToBase64(file);
      this.$store.commit("builder/SET_LOGO", this.logoFileBase64);
    },

    async checkAvailability() {
      try {
        this.checkingAvailability = true;
        const body = {
          domain: `${this.subdomain}.getbiew.com`,
          subdomain: this.subdomain
        };
        const res = await this.$store.dispatch("builder/verifyDomain", body);
        this.subdomainInputType = "is-success";
        this.subdomainInputMessage = res?.message || "";
        console.log("response res", res);
      } catch (error) {
        this.subdomainInputType = "is-danger";
        if (error?.response?.data?.message) {
          this.subdomainInputMessage = error.response.data.message;
        } else {
          this.subdomainInputMessage = "An error ocurred, try again or refresh";
        }
      } finally {
        this.checkingAvailability = false;
      }
    },

    setCustomDomain() {
      this.$emit("open-payment-modal");
    },

    openCustomDomainModal() {
      this.showCustomDomainModal = true;
    },

    deleteFavicon() {
      this.showFileLimitError = false;
      this.faviconButtonText = "Choose a favicon";
      this.$store.commit("builder/SET_FAVICON", "");
    },

    deleteLogo() {
      this.showFileLimitError = false;
      this.logoButtonText = "Choose a logo";
      this.$store.commit("builder/SET_LOGO", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.field.file.disabled {
  .button:hover {
    border-color: #dbdbdb;
    cursor: not-allowed;
  }

  span {
    color: #bbbbbb;
  }
}
</style>
